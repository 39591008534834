import { Controller } from '@hotwired/stimulus';
import { AgCharts } from "ag-charts-enterprise";
import { LicenseManager } from "ag-charts-enterprise";
import { ChartRegistry } from './chart_registry';

LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-057421}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ARMS_Software_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ARMS}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ARMS}_need_to_be_licensed___{ARMS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_April_2025}____[v3]_[0102]_MTc0NTcwODQwMDAwMA==cbe1062ba957e1fcce1195c161880cfb");

export default class ChartBaseController extends Controller {
  static targets = ['chart'];

  static values = {
    source: String,
    title: String,
    footnote: String,
  }

  observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  }

  connect() {
    this.chartRendered = false;
    this.chartInstance = null;

    // Skip JSON fetching unless a source URL is provided
    if (this.hasSourceValue) {
      this.observer = new IntersectionObserver(
        this.observerCallback.bind(this),
        this.observerOptions
      );

      this.observer.observe(this.chartTarget);
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  // public method called from external controllers
  async initializeChart(force = false) {
    // If we've never rendered the chart, render it now
    if (!this.chartRendered) {
      this.chartRendered = true;
      await this.#fetchDataAndRenderChart();
    }
    else if (force) {
      // If already rendered, *don't* re-created the chart; just re-register the same chart instance if needed
      if (this.chartInstance) {
        ChartRegistry.register(this.chartInstance);
      }
    }
  }

  async #fetchDataAndRenderChart() {
    try {
      const data = await this.#fetchChartData();
      const formattedData = this.formatData(data);
      this.renderChart(formattedData);
    } catch (error) {
      console.error('Error fetching data or rendering chart:', error);
    }
  }

  async #fetchChartData() {
    const response = await fetch(this.sourceValue, {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    return response.json();
  }

  // These methods should be implemented by child classes
  formatData() {
    throw new Error('formatData must be implemented by child class');
  }

  // Options shared across all chart types
  getChartOptions(data) {
    return {
      container: this.chartTarget,
      data: data,
      footnote: {
        text: this.footnoteValue,
      },
      legend: {
        position: 'right',
      },
      theme: 'ag-material',
    }
  }

  observerCallback(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && !this.chartRendered) {
        this.chartRendered = true;
        this.#fetchDataAndRenderChart();
      }
    })
  }

  renderChart(data) {
    const options = this.getChartOptions(data);
    this.chartInstance = AgCharts.create(options);

    ChartRegistry.register(this.chartInstance);

    this.element.dispatchEvent(new CustomEvent("chart:initialized", {
      bubbles: true,
      detail: { chart: this.chartInstance }
    }));
  }

  async getChartImageUrl() {
    const dataUrl = await this.localChart.getImageDataURL({ type: 'image/png' });
    return dataUrl;
  }

  printUrl(dataUrl) {
    console.log(dataUrl);
  }
}
