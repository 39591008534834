import ChartBaseController from "./chart_base_controller";

export default class ChartBarBenchmarkController extends ChartBaseController {
  // Class property to store campaign and benchmark data
  campaignData = null;

  static values = {
    ...ChartBaseController.values,
    xKey: String,
    yKey: String,
    yName: String,
    series: Array,
    data: Array,
  }

  formatData(data) {
    // Store the original JSON response for series formatting
    this.campaignData = data;

    // Format data for charting
    const allRatings = new Set();
    data.forEach(campaign => {
      Object.keys(campaign.ratings).forEach(rating => {
        allRatings.add(rating);
      });
    });

    const sortedRatings = Array.from(allRatings).sort((a, b) => a - b);

    return sortedRatings.map(rating => {
      const ratingObj = { rating };
      data.forEach(campaign => {
        ratingObj[campaign.form_campaign_id] = campaign.ratings[rating] || 0;
      });
      return ratingObj;
    });
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    options.series = this.formatSeries(this.campaignData);
    return options;
  }

  formatSeries(campaigns) {
    return campaigns.map((campaign) => ({
      type: 'bar',
      label: {
        formatter: function(params) {
          return params.value.toString();
        },
        enabled: true,
        fontSize: 16,
        placement: 'outside-end',
        color: '#999999',
      },
      xKey: this.xKeyValue,
      yKey: campaign.form_campaign_id,
      yName: campaign.form_campaign_name,
      cornerRadius: 2,
      fillOpacity: 1,
    }));
  }
}
