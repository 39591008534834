import { Controller } from '@hotwired/stimulus';
import { ChartRegistry } from './chart_registry';

export default class ExportPdfController extends Controller {
  static targets = ['trigger']

  static values = {
    source: String,
  }

  selectedSectionIds = []

  connect() {
    this.triggerWidth = this.triggerTarget.offsetWidth;
    this.triggerTarget.style.minWidth = this.triggerWidth + 'px';
  }

  // Chart instances will be stored either individually via intersection observer callback or in bulk when the export
  // PDF button is clicked
  storeChartInstance(event) {
    const { chart } = event.detail;

    // Only store unique instances
    if (!this.chartInstances.includes(chart)) {
      this.chartInstances.push(chart);
    }
  }

  // Called by the section selection modal checkboxes, this updates the fetch URL's form_section_ids params
  appendParams(event) {
    let el = event.target.previousElementSibling;
    const url = new URL(this.sourceValue, window.location.origin);

    if (!this.selectedSectionIds.includes(el.value)) {
      this.selectedSectionIds.push(el.value);
    }
    else {
      const index = this.selectedSectionIds.indexOf(el.value);
      if (index > -1) {
        this.selectedSectionIds.splice(index, 1);
      }
    }

    url.searchParams.set('form_section_ids', this.selectedSectionIds.join(','));
    this.sourceValue = url.toString();
  }

  async generate(event) {
    event.preventDefault();

    // Clear old references
    ChartRegistry.clear();

    // Build up any request body data from query params, etc.
    const responseBody = this.generateBody();

    // Identify which sections the user selected
    //const selectedSections = [...document.querySelectorAll('#modal_select_sections input[type="checkbox"]:checked')].map(checkbox => checkbox.value);
    const selectedSectionsEl = [...document.querySelectorAll('#modal_select_sections input[type="checkbox"]:checked')];
    const selectedSections = selectedSectionsEl.map(checkbox => checkbox.value);

    this.updateButtonText(event.target, false);

    // Force charts to initialize if needed
    await this.initializeSelectedCharts(selectedSections, true);

    // Gather all chart references from ChartRegistry
    const allCharts = ChartRegistry.charts;

    // Build an array of PNG objects
    const chartData = [];

    for (const chart of allCharts) {
      try {
        const options = chart.getOptions();
        const container = options.container;
        const parentSectionId = container.closest('[data-parent-section-id]')?.getAttribute('data-parent-section-id');

        // If the user didn't pick sections or user picked this section
        if (selectedSections.length === 0 || selectedSections.includes(parentSectionId)) {
          const labelId = container.getAttribute('data-label-id');
          const labelNumber = container.getAttribute('data-label-number');
          const png = await chart.getImageDataURL();

          chartData.push({ png, label_id: labelId, label_number: labelNumber });
        }
      } catch (error) {
        console.error('Error getting image data URL:', error);
      }
    }

    // Add chart data to the request body
    responseBody['chart_data'] = chartData;

    // Send the data to the server for PDF creation
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

    try {
      const response = await fetch(this.sourceValue, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          'X-CSRF-TOKEN': csrfToken,
        },
        body: JSON.stringify(responseBody)
      });

      // Download the returned PDF
      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');

      downloadLink.href = pdfUrl;
      downloadLink.download = 'analytics.pdf';
      downloadLink.click();
      URL.revokeObjectURL(pdfUrl);

      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }

      this.updateButtonText(event.target);
      selectedSectionsEl.forEach(element => element.checked = false);
    } catch (error) {
      console.error("Error posting chart images", error);
    }
  }

  async initializeSelectedCharts(selectedSections, forceAll = false) {
    const chartControllers = this.application.controllers.filter(controller => (
      [
        'chart-bar',
        'chart-pie',
        'chart-bar-stacked',
        'chart-bar-benchmark',
        'chart-pie-benchmark',
        'chart-bar-stacked-benchmark'
      ].includes(controller.identifier)
    ));

    // Force only the ones in the selected sections to initialize
    await Promise.all(
      chartControllers.map(controller => {
        const parentSectionId = controller.element.closest('div[data-parent-section-id]')?.getAttribute('data-parent-section-id') || '';

        if (forceAll || selectedSections.includes(parentSectionId)) {
          return controller.initializeChart(forceAll);
        }
        return Promise.resolve();
      })
    );

    await new Promise(resolve => setTimeout(resolve, 500));
  }

  async collectChartImages() {
    await new Promise(resolve => setTimeout(resolve, 500));

    const results = await Promise.all(
      this.chartInstances.map(async (chart) => {
        try {
          const { container } = chart.getOptions();
          const labelId = container.getAttribute('data-label-id');
          const labelNumber = container.getAttribute('data-label-number');
          const png = await chart.getImageDataURL();

          return { png, label_id: labelId, label_number: labelNumber };
        } catch (error) {
          console.error('Error getting image data URL:', error);
          return null;
        }
      })
    );
    console.log('this.chartInstances', this.chartInstances);
    return results.filter(item => item !== null);
  }

  generateBody() {
    const body = {};
    const urlParams = new URLSearchParams(window.location.search);

    // Handle single-value param
    if (urlParams.has('form_campaign_id')) {
      body['form_campaign_id'] = urlParams.get('form_campaign_id');
    }

    // Handle array params, getting all values for each key
    body['group_ids'] = urlParams.getAll('group_ids[]') || urlParams.getAll('group_ids') || [];
    body['benchmark_campaign_ids'] = urlParams.getAll('benchmark_campaign_ids[]') || urlParams.getAll('benchmark_campaign_ids') || [];

    if (body['group_ids'].length === 0) delete body['group_ids'];
    if (body['benchmark_campaign_ids'].length === 0) delete body['benchmark_campaign_ids'];

    return body;
  }

  updateButtonText(buttonEl, completed = true) {
    if (completed == true) {
      buttonEl.innerHTML = `
        <i class="fe fe-download"></i> Export PDF
      `;
      buttonEl.removeAttribute('disabled');
    } else {
      buttonEl.setAttribute('disabled', 'disabled');
      buttonEl.innerHTML = `
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Generating...</span>
        </div>
        Generating
      `;
    }
  }
}
