export const ChartRegistry = {
  charts: [],

  register(chart) {
    if (!this.charts.includes(chart)) this.charts.push(chart);
  },

  clear() {
    this.charts = [];
  }
}
