import ChartBaseController from "./chart_base_controller";
import StringMixin from "../mixins/string_mixin";

export default class ChartPieController extends ChartBaseController {
  static values = {
    ...ChartBaseController.values,
    response: String,
    count: String,
    theme: Array,
  }

  connect() {
    super.connect();
    if (this.hasThemeValue) {
      this.customTheme = {
        palette: {
          fills: this.themeValue
        }
      }
    }
    else {
      this.customTheme = 'ag-material'
    }
  }

  formatData(data) {
    return Object.entries(data).map(([key, value]) => ({
      [this.responseValue]: key,
      [this.countValue]: value,
    }));
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    const totalResponses = this.calcTotalResponses(data);

    options.theme = this.customTheme;
    options.series = [{
      type: 'donut',
      calloutLabelKey: this.responseValue,
      angleKey: this.countValue,
      sectorLabelKey: this.countValue,
      innerRadiusRatio: 0.5,
      innerLabels: [
        {
          text: totalResponses.toString(),
          fontSize: 24,
        },
        {
          text: 'Responses',
        }
      ],
      sectorSpacing: 3,
    }];

    return options;
  }

  calcTotalResponses(data) {
    return data.reduce((sum, obj) => sum + obj[this.countValue], 0);
  }
}

// Mix in the string methods
Object.assign(ChartPieController.prototype, StringMixin)
