import ChartBaseController from "./chart_base_controller";

export default class ChartStackedBarBenchmarkController extends ChartBaseController {
  campaignData = null;

  static values = {
    ...ChartBaseController.values,
    xKey: String,
    yKey: String,
    yName: String,
  }

  formatData(data) {
    this.campaignData = data;

    return data.map(campaign => {
      const campaignObj = {
        id: campaign.form_campaign_id,
        name: campaign.form_campaign_name,
      };

      Object.keys(campaign.ratings).forEach(rating => {
        campaignObj[rating] = campaign.ratings[rating];
      });

      return campaignObj;
    });
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    options.series = [
      {
        type: 'bar',
        xKey: 'name',
        yKey: 'yes',
        yName: 'yes',
        normalizedTo: 100,
        cornerRadius: 5,
        stacked: true,
      },
      {
        type: 'bar',
        xKey: 'name',
        yKey: 'no',
        yName: 'no',
        cornerRadius: 5,
        normalizedTo: 100,
        stacked: true,
      }
    ]
    return options;
  }

  formatSeries(campaigns) {
    return campaigns.map((campaign) => ({
      type: 'bar',
      xKey: 'id',
      yKey: 'yes',
      yName: 'yes',
      cornerRadius: 2,
      stacked: true,
    }))
  }
}
