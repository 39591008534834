import ChartBaseController from './chart_base_controller';

export default class ChartBarStackedBenchmarkController extends ChartBaseController {
  campaignData = null;

  static values = {
    ...ChartBaseController.values,
    xKey: String,
    yKeys: Array,
    direction: String,
  }

  chartBarStackedTheme = {
    palette: {
      fills: ["#F44336", "#FF9800", "#00C853"]
    }
  }

  formatData(data) {
    // Store the orignal JSON response for series formatting
    this.campaignData = data;

    const allRatings = [];
    data.forEach(campaign => {
      const ratings = campaign.ratings;
      allRatings.push(ratings);
    });
    return allRatings;
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    options.series = this.formatSeries(this.campaignData);
    options.theme = this.chartBarStackedTheme;
    return options;
  }

  formatSeries() {
    const series = []
    this.yKeysValue.forEach((yKey) => {
      series.push({
        type: 'bar',
        label: {
          formatter: function(params) {
            return params.value.toString();
          },
          enabled: true,
          fontSize: 18,
        },
        direction: 'horizontal',
        xKey: 'campaign_name',
        yKey: yKey,
        yKeyName: yKey,
        normalizedTo: 100,
        stacked: true,
      });
    });
    return series;
  }
}
