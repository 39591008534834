import ChartBaseController from "./chart_base_controller";

export default class ChartBarController extends ChartBaseController {
  static values = {
    ...ChartBaseController.values,
    xKey: String,
    yKey: String,
    yName: String,
    labelType: String, // allows label-specific conditions
  }

  formatData(data) {
    return Object.entries(data).map(([key, value]) => ({
      [this.xKeyValue]: key.includes('=>yes') ? key.replace('=>yes', '') : key,
      [this.yKeyValue]: value,
    }));
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    options.legend = { enabled: false };
    options.series = this.formatSeries();

    return options;
  }

  formatSeries() {
    return [{
      type: 'bar',
      label: {
        formatter: function(params) {
          return params.value.toString();
        },
        enabled: true,
        fontSize: 18,
        placement: 'outside-end',
        color: '#999999',
      },
      xKey: this.xKeyValue,
      yKey: this.yKeyValue,
      yName: this.yNameValue,
      cornerRadius: 4,
    }];
  }
}
