import ChartBaseController from './chart_base_controller';

export default class ChartPieBenchmarkController extends ChartBaseController {
  // Class property to store campaign and benchmark data
  campaignData = null;
  radiusRatio = 1;
  fills = [
    ["#FF6D00", "#FFE0B2"],
    ["#AA00FF", "#E1BEE7"],
    ["#00C853", "#C8E6C9"],
    ["#FF1744", "#FFCDD2"],
    ["#2962FF", "#BBDEFB"],
  ]
  static values = {
    ...ChartBaseController.values,
    angleKey: String,
    theme: Array,
  }

  formatData(data) {
    // Store the original JSON response for series formatting
    this.campaignData = data;

    console.log(this.campaignData)

    const allRatings = new Set();
    data.reverse().forEach((campaign) => {
      campaign.radiusRatio = this.#calcRadius(this.radiusRatio);

      if (campaign === data[data.length - 1]) {
        campaign.radiusRatio.inner = 0.2
      }
      Object.keys(campaign.ratings).forEach(rating => {
        allRatings.add(rating);
      });
    });

    return Array.from(allRatings).map(rating => {
      const ratingObj = { rating };

      data.slice().forEach(campaign => {
        ratingObj[campaign.form_campaign_id] = campaign.ratings[rating] || 0;
      });
      return ratingObj;
    });
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);

    if (this.hasThemeValue) {
      options.theme = {
        palette: {
          fills: this.themeValue
        }
      }
    }
    else {
      options.theme = 'ag-material'
    }
    options.series = this.formatSeries(this.campaignData);
    return options;
  }

  formatSeries(campaigns) {
    const sortedCampaigns = [...campaigns].reverse();
    const formattedCampaigns = sortedCampaigns.map((campaign) => ({
      type: 'donut',
      calloutLabelKey: 'rating',
      title: {
        text: campaign.form_campaign_name,
        showInLegend: true,
      },
      angleKey: campaign.form_campaign_id,
      outerRadiusRatio: campaign.radiusRatio.outer,
      innerRadiusRatio: campaign.radiusRatio.inner,
    }));

    formattedCampaigns.forEach((obj) => {
      obj['fills'] = this.fills.pop();
    });

    return formattedCampaigns;
  }

  #calcRadius(outerRadiusRatio) {
    const ratioObj = {
      outer: outerRadiusRatio,
      inner: outerRadiusRatio - 0.075,
    };

    // Adjust outerRadiusRatio's starting value
    this.radiusRatio = ratioObj.inner - 0.05;

    return ratioObj;
  }
}
