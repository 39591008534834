import ChartBaseController from "./chart_base_controller";
import { AgCharts } from "ag-charts-enterprise";
import { LicenseManager } from "ag-charts-enterprise";

LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-057421}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ARMS_Software_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ARMS}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ARMS}_need_to_be_licensed___{ARMS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_April_2025}____[v3]_[0102]_MTc0NTcwODQwMDAwMA==cbe1062ba957e1fcce1195c161880cfb");


export default class ChartLinearGaugeController extends ChartBaseController {
  static values = {
    ...ChartBaseController.values,
    count: Number,
    max: Number,
    direction: String,
    average: Number,
  }

  connect() {
    this.#renderChart();
  }

  #renderChart() {
    const options = this.getChartOptions();

    if (this.hasAverageValue && this.averageValue > 0) {
      options.targets = [
        {
          value: this.averageValue,
          placement: 'before',
          text: 'Form Average',
        },
      ];

      if (this.#calcPercentage() < this.averageValue) {
        options.bar = { fill: '#f44336' }
      } else {
        options.bar = { fill: '#00c853' }
      }
    } else {
      options.bar = { fill: '#007bff' }
    }

    AgCharts.createGauge(options);
  }

  getChartOptions() {
    return {
      animation: { duration: 1 },
      container: this.chartTarget,
      type: 'linear-gauge',
      footnote: { text: 'Percentage (%)' },
      direction: this.directionValue,
      value: this.#calcPercentage(),
      thickness: 50,
      label: {
        enabled: true,
        placement: 'inside-start',
        avoidCollisions: true,
      },
      scale: {
        min: 0,
        max: 100,
        label: {
          enabled: false,
        }
      },
      cornerRadius: 99,
      cornerMode: 'container',
    }
  }

  #calcPercentage() {
    if (this.maxValue === 0) return 0;
    if (this.countValue >= this.maxValue) return 100;

    return Math.ceil((this.countValue / this.maxValue) * 100);
  }
}
