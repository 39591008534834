import ChartBaseController from './chart_base_controller';

export default class ChartBarStackedController extends ChartBaseController {
  static values = {
    ...ChartBaseController.values,
    xKey: String,
    yKey: String,
    yName: String,
    direction: String,
  }

  chartBarStackedTheme = {
    palette: {
      fills: ["#F44336", "#FF9800", "#00C853"]
    }
  }

  formatData(data) {
    return [data];
  }

  getChartOptions(data) {
    const options = super.getChartOptions(data);
    options.legend = { enabled: false };
    options.theme = this.chartBarStackedTheme;
    options.series = this.formatSeries();

    return options;
  }

  formatSeries() {
    return [
      {
        type: 'bar',
        label: {
          formatter: function(params) {
            return params.value.toString();
          },
          enabled: true,
          fontSize: 18,
        },
        direction: 'horizontal',
        xKey: 'campaign_name',
        yKey: 'detractors',
        yName: 'Detractors',
        normalizedTo: 100,
        stacked: true,
      },
      {
        type: 'bar',
        label: {
          formatter: function(params) {
            return params.value.toString();
          },
          enabled: true,
          fontSize: 18,
        },
        direction: 'horizontal',
        xKey: 'campaign_name',
        yKey: 'passives',
        yName: 'Passives',
        normalizedTo: 100,
        stacked: true,
      },
      {
        type: 'bar',
        label: {
          formatter: function(params) {
            return params.value.toString();
          },
          enabled: true,
          fontSize: 18,
        },
        direction: 'horizontal',
        xKey: 'campaign_name',
        yKey: 'promoters',
        yName: 'Promoters',
        normalizedTo: 100,
        stacked: true,
      }
    ]
  }
}
