import { Controller } from '@hotwired/stimulus';

export default class SubmissionFiltersController extends Controller {
  connect() {
    this.paramString = 'form_campaign_id';
    this.previousSelection = [];
    this.formCampaignId = this.getUrlParameter();

    // Initially disable benchmark selection if no form campaign is selected
    this.toggleBenchmarkSelection();

    if (this.formCampaignId) {
      this.updatePreviousSelection();
    }

    this.addSelectListener();
  }

  getUrlParameter() {
    const paramName = this.paramString.replace(/[\[]/, '\\[').replace(/[\[]/, '\\]');
    const regEx = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
    const results = regEx.exec(window.location.search);

    return results == null ? null : decodeURIComponent(results[1].replace(/\+/g, ''));
  }

  updatePreviousSelection() {
    // Eww, jQuery.
    $('#benchmark_campaign_ids').find(`option[value="${this.formCampaignId}"]`).prop('disabled', true);
    $('#benchmark_campaign_ids').selectpicker('refresh');
    this.previousSelection.push(this.formCampaignId);
  }

  toggleBenchmarkSelection() {
    const formCampaignSelect = $('#form_campaign_id');
    const benchmarkSelect = $('#benchmark_campaign_ids');

    if (!formCampaignSelect.val()) {
      benchmarkSelect.prop('disabled', true);
      benchmarkSelect.selectpicker('refresh');
    } else {
      benchmarkSelect.prop('disabled', false);
      benchmarkSelect.selectpicker('refresh');
    }
  }

  addSelectListener() {
    $('#form_campaign_id').on('changed.bs.select', (event, clickedIndex, isSelected, previousValue) => {
      // If a new campaign is selected, reset any selected benchmark_campaign_ids
      $('#benchmark_campaign_ids').selectpicker('deselectAll');

      let currentValue = $(event.currentTarget).val();
      let currentSelection = Array.isArray(currentValue) ? currentValue : (currentValue ? [currentValue] : []);

      // Toggle benchmark selection based on form campaign selection
      this.toggleBenchmarkSelection();

      // Determine added and removed options
      let added = currentSelection.filter(value => !this.previousSelection.includes(value));
      let removed = this.previousSelection.filter(value => !currentSelection.includes(value));

      added.forEach(value => {
        $('#benchmark_campaign_ids').find(`option[value="${value}"]`).prop('disabled', true);
      });

      removed.forEach(value => {
        $('#benchmark_campaign_ids').find(`option[value="${value}"]`).prop('disabled', false);
      });

      $('#benchmark_campaign_ids').selectpicker('refresh');

      this.previousSelection = currentSelection;
    })
  }
}
